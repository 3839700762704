import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "perma-network-culture",
      "style": {
        "position": "relative"
      }
    }}>{`Perma-Network Culture`}<a parentName="h1" {...{
        "href": "#perma-network-culture",
        "aria-label": "perma network culture permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`So, we’ve `}<a parentName="p" {...{
        "href": "https://www.poetryfoundation.org/poems/54933/ode-"
      }}>{`built babel with our mirth`}</a>{`, but libraries have this nasty habit of burning down, often because they provide access to knowledge that threatens those in assumed positions of authority. So, we’ll also be looking at the idea of permanence and the kinds of tools we have at our disposal to ensure that what we build shared libraries has the durability of a pyramid or - even better - cycads and coelacanths.`}</p>
    <p>{`Before we get there, though, we'll need to have something worth making permanent. Which means, being good developers, that we're going to have to improve our contract, think about where it will live and how it will interact with other contracts that share its space, and what sort of creative objects we wish to propagate with it. We'll also need to write more tests to make sure it does what we intend it to, and that others will not be able to abuse it for lack of care or attention on our part.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Get an Etherscan and Infura API key.`}</li>
      <li parentName="ol"><em parentName="li">{`Optional`}</em>{`: install the `}<a parentName="li" {...{
          "href": "https://faucet.arweave.net/"
        }}>{`Arweave browser extension`}</a>{`. Let me know in #kernel-nfts how the faucet treats you.`}</li>
      <li parentName="ol"><em parentName="li">{`Optional`}</em>{`: watch previous firesides with Juan (IPFS) and David and Manasi (Sia), and Sam (Arweave) `}<a parentName="li" {...{
          "href": "https://kernel.community/en/learn/module-3/"
        }}>{`here`}</a>{`.`}</li>
    </ol>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><img parentName="p" {...{
            "src": "/images/build/impermanence.png",
            "alt": "Anita"
          }}></img></p>
        <p>{`Each session in this build guild can be found on its own branch in the repo below:`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://github.com/kernel-community/nfteasy/tree/lesson-2" mdxType="Button">Complete</Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/WnwZnpDNEIw" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      